import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import authService from "../../services/authService";
import userService from "../../services/userService";
import queryString from "query-string";
import logo from "../../img/logo_bwki.png";

class ChangePassword extends Form {
  constructor(props) {
    super(props);
    const { search } = props.location;
    const params = queryString.parse(search);

    document.body.classList.add("change-password");

    if (!params.token) {
      props.history.push("/");
    } else {
      this.isTokenValid(params.token);
    }

    this.state = {
      data: {
        password: "",
        confirmPassword: "",
        token: params.token,
      },
      loading: false,
      error: "",
      errors: {},
    };
  }

  state = {};
  schema = {
    password: Joi.string().required(),
    confirmPassword: Joi.string().valid(Joi.ref("password")).required(),
    token: Joi.string().required(),
  };

  createMarkup = (text) => {
    return { __html: text };
  };

  submitForm = () => {
    this.setState({
      loading: true,
    });

    const { password, confirmPassword, token } = this.state.data;
    userService
      .changePassword(password, confirmPassword, token)
      .then(({ data }) => {
        this.props.history.push("/auth");
      })
      .catch((err) => {
        let error = "Es ist ein unerwarteter Fehler aufgetreten.";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
        }

        this.setState({
          error,
          loading: false,
        });
      });
  };

  isTokenValid = (token) => {
    authService
      .verifyChangePasswordToken(token)
      .then(({ data }) => {
        if (!data) {
          this.props.history.push("/");
        }
      })
      .catch((err) => {
        this.props.history.push("/");
      });
  };

  render() {
    const user = authService.getCurrentUser();
    if (user) this.props.history.push("/");

    const { errors, loading, error, data } = this.state;
    const { password, confirmPassword } = data;

    return (
      <div className="page">
        <div className="login">
          <div className="nav-login">
            <a href="#/" className="active user-login">
              <span>Change Password</span>
            </a>
          </div>
          <div className="content-login">
            <div className="content-login-wrapper forgot-content active">
              <div className="login-logo">
                <img alt="logo" src={logo} />
                <h1>
                  {" "}
                  <span>Bundeswettbewerb</span> <br /> Künstliche Intelligenz{" "}
                </h1>
              </div>
              <form>
                <div className="input-wrapper">
                  <input
                    className={`${errors.password ? "invalid" : ""}`}
                    name="password"
                    onChange={this.handleChange}
                    value={password}
                    type="password"
                    placeholder="Password"
                  />
                  <input
                    className={`${errors.confirmPassword ? "invalid" : ""}`}
                    name="confirmPassword"
                    onChange={this.handleChange}
                    value={confirmPassword}
                    type="password"
                    placeholder="Confirm Password"
                    data-depend="password"
                  />
                </div>
              </form>
              {error && (
                <div className="error text-center">
                  <span className="text-danger">{error}</span>
                </div>
              )}
              <div className="button-login">
                <button
                  disabled={loading ? true : this.validate()}
                  type="button"
                  onClick={this.handleSubmit}
                >
                  {loading ? "Loading..." : "Save"}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
