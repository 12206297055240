import React from "react";
import { FilePond, File } from "react-filepond";
import "filepond/dist/filepond.min.css";
import {
  getIdeaSubmissionDeadline,
  getProjectSubmissionDeadline,
} from "../../services/deadlineService";

const SubmitIdeaForm = ({
  data,
  loading,
  showImageError,
  handleChange,
  handleCharChange,
  validate,
  handleSubmit,
  loadingError,
  showExistingFile,
  existingFileName,
  hasSubmit,
  showCompleted,
  handleExistingFileDelete,
  imageError,
}) => {
  const {
    teamName,
    thema,
    thema_count,
    description,
    description_count,
    origin,
    origin_count,
    files,
  } = data;

  const ideaSubmissionDeadline = getIdeaSubmissionDeadline();
  const projectSubmissionDeadline = getProjectSubmissionDeadline();
  // console.log(ideaSubmissionDeadline)
  // console.log(projectSubmissionDeadline)

  return (
    <div className="custom-form-wrapper">
      <div className="row">
        <div className="col-md-12">
          <h1 className="primary-color-lightblue-foreground text-center">
            Idee anmelden
          </h1>
          <div className="header2 font-sub-header text-justify">
            <span>
              Du möchtest beim Bundeswettbewerb Künstliche Intelligenz
              mitmachen! Wenn du dein Team gebildet hast, kann ein Mitglied aus
              eurem Team eine Projektidee einreichen.
            </span>
          </div>
          <h2 className="primary-color-lightblue-foreground mt-4">
            Eure Projektidee muss bis spätestens {ideaSubmissionDeadline}{" "}
            angemeldet sein.
          </h2>
          <div className="header2 font-sub-header text-justify">
            <span>
              Beschreibt eure Idee und überlegt euch gut, woher ihr eure Daten
              bekommt. Wenn ihr euer Projekt grafisch darstellen möchtet, habt
              ihr auch die Möglichkeit Bilder hochzuladen. Sobald ihr auf
              „Absenden“ klickt, reicht ihr eure Projektanmeldung ein und könnt
              sie nicht mehr ändern.
            </span>
          </div>
        </div>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="row mt-5">
          <div className="form-group col-md-12">
            <label htmlFor="Teamname">Team</label>
            <input
              className="form-control"
              name="teamName"
              id="teamName"
              readOnly
              value={teamName}
              onChange={handleChange}
              type="text"
            />
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label htmlFor="Teamname">
              <h5>Euer Projektname</h5>
              Unter diesem Titel stellen wir euer Projekt vor (z.B. beim Finale,
              in der Presse, beim Erstellen von Urkunden) <br />
              Beispiel: „FoodAI – Die KI gegen Lebensmittelverschwendung”
              <br />
              Achtung: Der Titel kann später nicht mehr geändert werden. (max.
              60 Zeichen inkl. Leerzeichen) <br />
            </label>
            <input
              className="form-control"
              name="thema"
              id="thema"
              readOnly={hasSubmit}
              value={thema}
              onChange={handleCharChange("thema", 60)}
              type="text"
            />
            <span className="float-right">Zeichen: {thema_count}/60</span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label htmlFor="labelDesc">
              Beschreibung eurer Projektidee (max. 1000 Zeichen inkl.
              Leerzeichen)
            </label>
            <textarea
              className="form-control"
              name="description"
              readOnly={hasSubmit}
              id="description"
              value={description}
              onChange={handleCharChange("description", 1000)}
            ></textarea>
            <span className="float-right">
              Zeichen: {description_count}/1000
            </span>
          </div>
        </div>
        <div className="row">
          <div className="form-group col-md-12">
            <label htmlFor="labelDesc">
              Woher bekommt ihr eure Daten? (max. 1000 Zeichen inkl.
              Leerzeichen)
            </label>
            <textarea
              className="form-control"
              name="origin"
              id="origin"
              readOnly={hasSubmit}
              value={origin}
              onChange={handleCharChange("origin", 1000)}
            ></textarea>
            <span className="float-right">Zeichen: {origin_count}/1000</span>
          </div>
        </div>
        <div className="row">
          <div className="datei col-md-12">
            {!hasSubmit && (
              <label htmlFor="labelDesc">
                Datei anhängen(Optional) (max. 10MB)
              </label>
            )}
            {/* Hot fix for production to accept all files types */}
            {!showExistingFile && !hasSubmit && (
              <FilePond
                allowMultiple={true}
                maxFiles={1}
                beforeAddFile={(file) => {
                  if (file.fileSize / 1000000 > 10) {
                    showImageError(true);
                    return false;
                  } else showImageError(false);
                }}
                onupdatefiles={(fileItems) => {
                  handleChange({
                    currentTarget: {
                      type: "file",
                      name: "files",
                      value: fileItems.map((fileItem) => fileItem.file),
                    },
                  });
                }}
              >
                {files.map((file) => (
                  <File key={file} src={file} origin="local" />
                ))}
              </FilePond>
            )}

            {showExistingFile && (
              <div className="pdf existing-files">
                <div className="file-info">
                  {!hasSubmit && (
                    <div
                      className="clear-button"
                      onClick={handleExistingFileDelete}
                    >
                      <div className="clear-icon">x</div>
                    </div>
                  )}
                  <span>{existingFileName}</span>
                </div>
              </div>
            )}
          </div>
        </div>
        {imageError && (
          <div className="alert alert-danger">
            Du kannst Dateien bis zu maximal 10MB hochladen.
          </div>
        )}
        {loadingError && (
          <div className="alert alert-danger">{loadingError}</div>
        )}

        {!hasSubmit && (
          <div className="row">
            <div className="col-md-12">
              <button
                disabled={loading ? loading : validate()}
                className="myButton no-margin-top"
              >
                {loading ? "Loading..." : "Absenden"}
              </button>
            </div>
          </div>
        )}
        {showCompleted && (
          <div className="alert alert-success">
            Du hast deine Idee erfolgreich eingereicht.
          </div>
        )}
        <div className="row">
          <div className="col-md-12 mt-5">
            <h2 className="primary-color-lightblue-foreground">
              Euer Projekt muss bis zum {projectSubmissionDeadline} eingereicht
              werden!
            </h2>

            <p>
              Schaut euch schon mal an, welche Kriterien wir für die
              Projekteinreichung berücksichtigen:
              <br />
              &nbsp;&nbsp;- Praktische Relevanz und Innovationskraft
              <br />
              &nbsp;&nbsp;- Eingesetzte Methoden des maschinellen Lernens
              <br />
              &nbsp;&nbsp;- Euer Ergebnis
              <br />
              &nbsp;&nbsp;- Kritische Reflexion des eigenen Projekts
              <br />
              &nbsp;&nbsp;- Die Präsentation des Projekts in einem Video Pitch
              <br />
            </p>
            <p className="text-center mt-2">
              Probleme? Dann schreibt uns:{" "}
              <a href="mailto:info@bw-ki.de">
                <b>info@bw-ki.de</b>
              </a>
            </p>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SubmitIdeaForm;
