import React from "react";
import Form from "../common/form";
import Joi from "joi-browser";
import userService from "../../services/userService";
import teamService from "../../services/teamService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
  faSignOutAlt,
} from "@fortawesome/free-solid-svg-icons";
import { getIdeaSubmissionDeadline } from "../../services/deadlineService";

class TeamGruenden extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        teamName: "",
        username: "",
        username1: "",
        username2: "",
        username3: "",
        isTeamCreated: false,
        showLeaveButton: false,
        pendingAcceptance: [],
        acceptance: [],
      },
      teamSize: 0,
      success: "",
      noClickable: true,
      errors: [],
      invalidUsers: [],
      invalidUserInTeamFormation: false,
      alreadyExistingUserWithTeam: false,
      warnUserInvite: false,
      warnSameUser: false,
      existingUsers: [],
      clearError: false,
    };

    this.ideaSubmissionDeadline = getIdeaSubmissionDeadline();
  }

  parseTeamMembers = (teamMembers) => {
    const { data } = this.state;

    let existingUsers = [];
    var accept_count = 0;
    var idx = 0;
    for (let i = 0; i < teamMembers.length; i++) {
      if (teamMembers[i].username !== data.username) {
        idx = idx + 1;
        data["username" + idx] = teamMembers[i].username;
      }
      //  REMOVE ???
      else {
        data.username = teamMembers[i].username;
      }

      existingUsers.push(`username${i}`);
      if (teamMembers[i].accepted) {
        accept_count += 1;
        data.acceptance.push(teamMembers[i].username);
      } else {
        data.pendingAcceptance.push(teamMembers[i].username);
      }
    }
    if (accept_count >= 2) {
      data.showLeaveButton = true;
    }

    // Is it better to set the state here?
    return [data, existingUsers];
  };

  componentWillReceiveProps(nextProps) {
    const { data } = this.state;
    let existingUsers = [];
    var teamSize = 0;
    // Check if user is in a team
    if (nextProps.response.team) {
      data.teamName = nextProps.response.team.team_name;
      data.isTeamCreated = true;
      var teamMembers = nextProps.response.team.team_members;
      teamSize = teamMembers.length;
      var accept_count = 0;
      var idx = 0;
      for (let i = 0; i < teamMembers.length; i++) {
        if (teamMembers[i].username !== nextProps.response.username) {
          idx = idx + 1;
          data["username" + idx] = teamMembers[i].username;
        } else {
          data.username = nextProps.response.username;
        }

        existingUsers.push(`username${i}`);
        if (teamMembers[i].accepted) {
          accept_count += 1;
          data.acceptance.push(teamMembers[i].username);
        } else {
          data.pendingAcceptance.push(teamMembers[i].username);
        }
      }
      if (accept_count >= 2) {
        data.showLeaveButton = true;
      }
    } else {
      data.teamName = "";
      data.username = nextProps.response.username;
      data.isTeamCreated = false;
      data.username1 = "";
      data.username2 = "";
      data.username3 = "";
      data.isTeamCreated = false;
      data.showLeaveButton = false;
      // Check if user has pending team invitations
      if (
        nextProps.response.hasOwnProperty("team_invitations") &&
        nextProps.response.team_invitations.length > 0
      ) {
        data.pendingAcceptance = nextProps.response.team_invitations;
      }
    }
    this.setState({
      data: data,
      teamSize: teamSize,
      existingUsers,
      success: "",
      noClickable: true,
    });
  }

  schema = {
    teamName: Joi.string().required(),
    username: Joi.string().allow("").required(),
    username1: Joi.string().allow("").optional(),
    username2: Joi.string().allow("").optional(),
    username3: Joi.string().allow("").optional(),
    isTeamCreated: Joi.boolean().optional(),
    showLeaveButton: Joi.boolean().optional(),
    pendingAcceptance: Joi.array().items(Joi.string().allow("").optional()),
    acceptance: Joi.array().items(Joi.string().allow("").optional()),
  };

  // Dash spaces, lowercase
  handleNameChange = ({ currentTarget: input }) => {
    const { data } = this.state;
    const errors = { ...this.state.errors };
    var inputName = input.value;
    if (inputName) {
      if (/\s+/g.test(inputName)) {
        data.teamName = inputName.replace(/\s+/g, "_").toLowerCase();
      } else {
        data.teamName = inputName.toLowerCase();
      }
      this.setState({
        data,
        noClickable: false,
        errors,
      });
    } else {
      data.teamName = "";
      this.setState({
        data,
        noClickable: true,
        errors,
      });
    }
  };

  handleUserNameChange = ({ currentTarget: input }) => {
    const { data, teamSize } = this.state;
    data[input.name] = input.value;
    this.setState({ data, clearError: false, noClickable: false });

    var invalidUsers = { ...this.state.invalidUsers };
    let inputname = input.value;
    let invalidUserInTeamFormation = 0;
    let alreadyExistingUserWithTeam = 0;
    let warnUserInvite = 0;

    if (inputname) {
      //same username before team
      if (teamSize === 0 && inputname === data.username) {
        this.setState({
          warnSameUser: true,
          invalidUserInTeamFormation: false,
          noClickable: true,
          warnUserInvite: false,
        });
        return;
      }

      // pending and accepted scenario within team
      if (
        data.pendingAcceptance.length > 0 &&
        data.pendingAcceptance.includes(inputname)
      ) {
        this.setState({
          warnSameUser: true,
          noClickable: true,
          invalidUserInTeamFormation: false,
          warnUserInvite: false,
        });
        return;
      } else if (
        data.acceptance.length > 0 &&
        data.acceptance.includes(inputname)
      ) {
        this.setState({
          warnSameUser: true,
          noClickable: true,
          invalidUserInTeamFormation: false,
          warnUserInvite: false,
        });
        return;
      }
      // normal user scenario with team checking
      userService
        .getUserByUsername(inputname)
        .then((response) => {
          delete invalidUsers[input.name];
          invalidUserInTeamFormation =
            Object.keys(invalidUsers).length > 0 ? true : false;
          // check if the team invitation exist
          if (
            response.data.hasOwnProperty("team_invitations") &&
            response.data.team_invitations.length > 0
          ) {
            warnUserInvite = true;
            this.setState({
              invalidUsers,
              alreadyExistingUserWithTeam: false,
              invalidUserInTeamFormation,
              warnSameUser: false,
              warnUserInvite,
              noClickable: false,
            });
          } else if (response.data.team && response.data.team !== null) {
            console.log("The user already exists in the team");
            this.setState({
              invalidUsers,
              alreadyExistingUserWithTeam: true,
              warnSameUser: false,
              warnUserInvite: false,
              invalidUserInTeamFormation,
              noClickable: true,
            });
          } else {
            this.setState({
              invalidUsers,
              alreadyExistingUserWithTeam: false,
              warnSameUser: false,
              warnUserInvite: false,
              invalidUserInTeamFormation,
              noClickable: false,
            });
          }
        })
        .catch((e) => {
          invalidUsers[input.name] = input.name;
          invalidUserInTeamFormation = true;
          alreadyExistingUserWithTeam = false;

          if (!this.state.clearError) {
            this.setState({
              invalidUsers,
              invalidUserInTeamFormation,
              warnSameUser: false,
              warnUserInvite: false,
              alreadyExistingUserWithTeam,
              noClickable: true,
            });
          }
        });
    } else {
      let noClicking = teamSize === 0 ? false : true;
      delete invalidUsers[input.name];
      invalidUserInTeamFormation = invalidUserInTeamFormation =
        Object.keys(invalidUsers).length > 0 ? true : false;
      this.setState({
        invalidUsers,
        invalidUserInTeamFormation,
        warnSameUser: false,
        warnUserInvite: false,
        noClickable: noClicking,
        clearError: true,
      });
    }
  };

  submitForm = () => {
    const { data } = this.state;
    const {
      teamName,
      username,
      username1,
      username2,
      username3,
      isTeamCreated,
    } = data;

    // Update team
    if (isTeamCreated) {
      teamService
        .updateTeam(teamName, username, username1, username2, username3)
        .then(({ data: response }) => {
          const [data, existingUsers] = this.parseTeamMembers(
            response.team_members
          );
          this.setState({
            data: data,
            existingUsers: existingUsers,
            success: "true",
            noClickable: true,
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            success: "false",
          });
        });
    }
    // Create team
    else {
      // Validate team name before POST
      // Replace unicode characters with hyphens
      // Trim leading/trailing spaces
      var inputStrippedUnicode = teamName
        .replace(
          /[\u00A0\u1680​\u180e\u2000\u2800\u2009\u200a​\u200b​\u202f\u205f​\u3000]/g,
          "-"
        )
        .trim();
      // Replace special characters with underscores
      var teamNameCleaned = inputStrippedUnicode
        .replace(/[.*'";+^${}()|[\]\\]/g, "")
        .replace(/\s+/g, "_")
        .toLowerCase();
      teamService
        .createTeam(teamNameCleaned, username, username1, username2, username3)
        .then(({ data: response }) => {
          const [data, existingUsers] = this.parseTeamMembers(
            response.team_members
          );
          data.teamName = response.team_name;
          data.isTeamCreated = true;
          this.setState({
            data: data,
            existingUsers: existingUsers,
            success: "true",
            noClickable: true,
          });
        })
        .catch((err) => {
          this.setState({
            success: "false",
          });
          let error =
            "Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!";
          console.log(err);
          if (err.response && err.response.status === 400) {
            error =
              "Dieser Teamname ist bereits vergeben, bitte wähle einen anderen.";
            this.setState({
              success: "false400",
              errors: [error],
            });
          }
        });
    }
    setTimeout(() => {
      this.setState({
        success: "",
      });
    }, 5000);
  };

  render() {
    const {
      data,
      errors,
      success,
      spaceWarning,
      teamSize,
      invalidUserInTeamFormation,
      alreadyExistingUserWithTeam,
      warnUserInvite,
      warnSameUser,
      invalidUsers,
      existingUsers,
      noClickable,
    } = this.state;
    const {
      teamName,
      username,
      username1,
      username2,
      username3,
      isTeamCreated,
      pendingAcceptance,
      showLeaveButton,
    } = data;
    const { leaveStatus, showPanel, modal } = this.props;
    const modalItem = {
      title: "Team verlassen",
      content: "Bist du sicher, dass du dein Team verlassen möchtest?",
    };
    const teamModalItem = {
      title: "",
      content:
        "Wenn du ein Team gründest, hast du die Möglichkeit weitere Mitglieder einzuladen. \
        Nehme die Benutzernamen deiner zukünftigen  Teammitglieder auf und klicke auf Hinzufügen.\
        Die Teammitglieder erhalten eine Einladung per Email. \
        Sobald die Einladung angenommen wurde, ändert sich der Status der eingeladenen Mitglieder.\
        Nun könnt ihr eure Projektidee anmelden. Jeder Nutzer darf nur in einem Team vertreten sein.",
    };
    var is_disabled = false;
    if (pendingAcceptance.length > 0 && pendingAcceptance.includes(username)) {
      is_disabled = true;
    }

    return (
      <div className="col-md-12">
        <div className={`row`}>
          <div className="text-center col-md-12">
            <h1 className="primary-color-blue-foreground"> Team gründen</h1>

            {invalidUserInTeamFormation && (
              <p className={`bold-red`}>
                Die Benutzer, die du für das Team eingetragen hast, sind bisher
                nicht registriert.
              </p>
            )}
            {alreadyExistingUserWithTeam && (
              <p className={`bold-red`}>
                Dieser Nutzer ist bereits in einem anderen Team.
              </p>
            )}
            {warnUserInvite && (
              <p className={`bold-warning`}>
                Dieser Benutzer hat eine Einladung von einem anderen Team.
              </p>
            )}
            {warnSameUser && (
              <p className={`bold-warning`}>
                Du versuchst, ein Mitglied hinzuzufügen, das Teil deines Teams
                ist.
              </p>
            )}

            <div className="header2 font-sub-header text-center mt-3">
              <span>
                Um am Bundeswettbewerb KI teilzunehmen, müsst ihr zunächst ein
                Team aus 1 - 4 Mitgliedern gründen.
              </span>
              <br />
              <span>
                Du hast eine Idee, aber dir fehlt das Team? Du willst dich einem
                Team anschließen?
                <a href="team-findung" className="link-deafult">
                  {" "}
                  Hier
                </a>{" "}
                könnt ihr suchen und finden.
              </span>
            </div>
          </div>
        </div>
        <br />
        {!teamName && (
          <div className="row mt-3">
            {pendingAcceptance.length > 0 &&
              pendingAcceptance.map((item, index) => {
                return (
                  <div
                    className="alert alert-info col-md-12 alert-team-bilden ml-3"
                    key={index}
                  >
                    Du hast eine Einladung von das Team {item} bekommen.
                  </div>
                );
              })}
          </div>
        )}
        <div className={`row mt-3`}>
          <div className="form-group col-md-4">
            <label htmlFor="Teamname ">Teamname </label>
            <input
              name="teamname"
              value={teamName}
              onChange={this.handleNameChange}
              type="text"
              placeholder={"Teamname"}
              className={`form-control ${errors.teamName ? "invalid" : ""}`}
              disabled={isTeamCreated}
            />
            <span style={{ fontSize: "20px", zIndex: 123 }}>
              <span
                className="question_icon"
                onClick={this.props.viewPanel(teamModalItem)}
              >
                <FontAwesomeIcon className="icon" icon={faQuestionCircle} />
              </span>
            </span>
          </div>
          <div className="col-md-1"></div>

          {
            // (pendingAcceptance.length > 0 && pendingAcceptance.includes(username)) || (acceptance.length > 0 && acceptance.includes(username))
            this.state.existingUsers.length > 0 &&
            existingUsers.includes("username0") ? (
              <div className="form-group col-md-4">
                <label htmlFor="Benutzername ">Benutzername </label>
                <input
                  name="username"
                  className="form-control"
                  value={username}
                  type="text"
                  disabled
                />
              </div>
            ) : (
              <div className="form-group col-md-4">
                <label htmlFor="Benutzername ">Benutzername </label>
                <input
                  name="username"
                  className="form-control"
                  value={username}
                  type="text"
                  disabled
                />
              </div>
            )
          }
          {pendingAcceptance.length > 0 &&
            pendingAcceptance.includes(username) && (
              <p
                className={`alert alert-info col-md-2 alert-team-bilden ml-3`}
                role="alert"
              >
                Teameinladung akzeptieren
              </p>
            )}

          {(teamSize === 1 || showLeaveButton) && (
            <div className="col-md-3">
              <button
                className="btn-sm btn-primary leave-team"
                onClick={this.props.viewPanel(modalItem)}
              >
                <FontAwesomeIcon className="icon" icon={faSignOutAlt} />
                Team verlassen
              </button>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-5"></div>
          {
            // (pendingAcceptance.length > 0 && pendingAcceptance.includes(username1)) || (acceptance.length > 0 && acceptance.includes(username1)) ?
            this.state.existingUsers.length > 0 &&
            existingUsers.includes("username1") ? (
              <div className={`form-group col-md-4`}>
                <input
                  name="username1"
                  value={username1}
                  onChange={this.handleUserNameChange}
                  type="text"
                  placeholder={"Benutzername 2"}
                  className={`form-control ${
                    invalidUsers.username1 ? "invalid" : ""
                  }`}
                  disabled
                />
              </div>
            ) : (
              <div className={`form-group col-md-4`}>
                <input
                  name="username1"
                  value={username1}
                  onChange={this.handleUserNameChange}
                  type="text"
                  placeholder={"Benutzername 2"}
                  className={`form-control ${
                    invalidUsers.username1 ? "invalid" : ""
                  }`}
                  disabled={is_disabled}
                />
              </div>
            )
          }
          {pendingAcceptance.length > 0 &&
            pendingAcceptance.includes(username1) && (
              <div
                className={`alert alert-info col-md-2 alert-team-bilden ml-3`}
                role="alert"
              >
                Ausstehend
              </div>
            )}
        </div>
        <div className="row">
          <div className="col-md-5"></div>
          {
            // (pendingAcceptance.length > 0 && pendingAcceptance.includes(username2)) || (acceptance.length > 0 && acceptance.includes(username2)) ?
            this.state.existingUsers.length > 0 &&
            existingUsers.includes("username2") ? (
              <div className={`form-group col-md-4`}>
                <input
                  name="username2"
                  value={username2}
                  onChange={this.handleUserNameChange}
                  type="text"
                  placeholder={"Benutzername 3"}
                  className={`form-control ${
                    invalidUsers.username2 ? "invalid" : ""
                  }`}
                  disabled
                />
              </div>
            ) : (
              <div className={`form-group col-md-4`}>
                <input
                  name="username2"
                  value={username2}
                  onChange={this.handleUserNameChange}
                  type="text"
                  placeholder={"Benutzername 3"}
                  className={`form-control ${
                    invalidUsers.username2 ? "invalid" : ""
                  }`}
                  disabled={is_disabled}
                />
              </div>
            )
          }
          {pendingAcceptance.length > 0 &&
            pendingAcceptance.includes(username2) && (
              <p
                className={`alert alert-info col-md-2 alert-team-bilden ml-3`}
                role="alert"
              >
                Ausstehend
              </p>
            )}
        </div>
        <div className="row">
          <div className="col-md-5"></div>
          {
            // (pendingAcceptance.length > 0 && pendingAcceptance.includes(username3)) || (acceptance.length > 0 && acceptance.includes(username3)) ?
            this.state.existingUsers.length > 0 &&
            existingUsers.includes("username3") ? (
              <div className={`form-group col-md-4`}>
                <input
                  name="username3"
                  value={username3}
                  onChange={this.handleUserNameChange}
                  type="text"
                  placeholder={"Benutzername 4"}
                  className={`form-control ${
                    invalidUsers.username3 ? "invalid" : ""
                  }`}
                  disabled
                />
              </div>
            ) : (
              <div className={`form-group col-md-4`}>
                <input
                  name="username3"
                  value={username3}
                  onChange={this.handleUserNameChange}
                  type="text"
                  placeholder={"Benutzername 4"}
                  className={`form-control ${
                    invalidUsers.username3 ? "invalid" : ""
                  }`}
                  disabled={is_disabled}
                />
              </div>
            )
          }
          {pendingAcceptance.length > 0 &&
            pendingAcceptance.includes(username3) && (
              <p
                className={`alert alert-info col-md-2 alert-team-bilden ml-3`}
                role="alert"
              >
                Ausstehend
              </p>
            )}
        </div>

        <div className="row text-center">
          {success === "true" && isTeamCreated ? (
            <div className="col-md-12 question-info text-center">
              <span>
                <i className="fa fa-check"></i>Teamdetails werden aktualisiert.
              </span>
            </div>
          ) : (
            success === "true" && (
              <div className="col-md-12 question-info">
                <span>
                  <i className="fa fa-check"></i>Team wurde erfolgreich
                  erstellt.
                </span>
              </div>
            )
          )}
          {success === "false400" && (
            <div className="col-md-12 question-info error">
              <span>
                <i className="fa fa-times"></i>Dieser Teamname ist bereits
                vergeben, bitte wähle einen anderen.
              </span>
            </div>
          )}
          {success === "false" && (
            <div className="col-md-12 question-info error">
              <span>
                <i className="fa fa-times"></i> Deine Eingaben konntet nicht
                gespeichert werden.
              </span>
            </div>
          )}

          <div className="col-md-12">
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                spaceWarning || noClickable || invalidUserInTeamFormation
                  ? true
                  : false
              }
              onClick={this.handleSubmit}
            >
              Hinzufügen
            </button>
            {/* <button onClick={handleCancel}>widerrufen</button> */}
          </div>
          {leaveStatus === "true" && (
            <div className="col-md-12  question-info">
              <span>
                <i className="fa fa-times"></i>Du hast das Team erfolgreich
                verlassen! Nun kannst du ein neues Team erstellen.
              </span>
            </div>
          )}
          {leaveStatus === "false" && (
            <div className="col-md-12  question-info error">
              <span>
                <i className="fa fa-times"></i>Es ist etwas schief gegangen.
                Versuche es nach einiger Zeit erneut.
              </span>
            </div>
          )}
        </div>
        <div className="row">
          <div className="col-md-12 mt-5">
            <h2 className="primary-color-blue-foreground">
              Eure Projektidee muss <b>bis zum {this.ideaSubmissionDeadline}</b>{" "}
              angemeldet sein!
            </h2>
            <p className="text-center mt-2">
              Probleme? Dann schreibt uns:{" "}
              <a href="mailto:info@bw-ki.de">
                <b>info@bw-ki.de</b>
              </a>
            </p>
          </div>
        </div>
        {showPanel && modal && (
          <div className="overlay-panel-container">
            <div className="overlay-panel leave-team-popup">
              <div className="definition-header">{modal.title}</div>
              {modal.title.length > 0 ? (
                <div className="section pt-1 pb-1">
                  <p className="text-justify">{modal.content}</p>
                  <button
                    type="button"
                    className="btn btn-primary mr-2 mt-2"
                    onClick={this.props.leaveMyTeam(teamName, isTeamCreated)}
                  >
                    Ja
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary mt-2"
                    onClick={this.props.closeDefinition}
                  >
                    Nein
                  </button>
                </div>
              ) : (
                <div className="section">
                  <p className="text-justify">{modal.content}</p>
                  {/* <button type="button" className='btn btn-primary mt-2 float-right' onClick={this.props.closeDefinition}>Schließen</button>*/}
                  <div
                    className="close"
                    onClick={this.props.closeDefinition}
                  ></div>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default TeamGruenden;
