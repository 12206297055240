import React, { Component } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import Logout from "./components/auth/logout";
import Ideas from "./components/ideas/ideas";
import Projects from "./components/projects/projects";
import NotFound from "./components/not-found";
import ProtectedRoute from "./components/common/protected-route";
import authService from "./services/authService";
import ForgotPassword from "./components/auth/forgot-password";
import ChangePassword from "./components/auth/change-password";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import Home from "./components/home";
import Footer from "./components/footer";
import Auth from "./components/auth/auth";
import ConfirmUser from "./components/auth/confirm-user";
import ConfirmTeam from "./components/auth/confirm-team";
import TeamFindung from "./components/team-findung/team-findung";
import AddTeamFindung from "./components/team-findung/add-team-findung";
import ViewTeamFindung from "./components/team-findung/view-team-findung";
import UserProfile from "./components/profile/user_profile";
import EntryPage from "./components/entry_page";
import AppBanner from "./components/appBanner";
import Team from "./components/team-gruenden/team";

class App extends Component {
  render() {
    const user = authService.getCurrentUser();
    return (
      <Route
        render={({ location }) => (
          <React.Fragment>
            {location.pathname !== "/auth" &&
              location.pathname !== "/change-password" &&
              location.pathname !== "/confirm-user" &&
              location.pathname !== "/confirm-team" &&
              location.pathname !== "/entry-page" && (
                <AppBanner
                  role={location.pathname === "/entry-page"}
                  path={location.pathname}
                />
              )}
            <TransitionGroup>
              <CSSTransition key={location.key} timeout={300} classNames="fade">
                <React.Fragment>
                  <Switch>
                    <Route path="/auth" component={Auth} />
                    <Route path="/logout" component={Logout} />
                    <Route path="/entry-page" component={EntryPage} />
                    <Route path="/forgot-password" component={ForgotPassword} />
                    <Route path="/change-password" component={ChangePassword} />
                    <Route path="/confirm-user" component={ConfirmUser} />
                    <Route path="/confirm-team" component={ConfirmTeam} />
                    <ProtectedRoute
                      path="/submit-ideas"
                      component={Ideas}
                      user={user}
                    />
                    {/* <ProtectedRoute
                      path="/submit-project/submit-postcards"
                      component={Postcards}
                      user={user}
                    /> */}
                    <ProtectedRoute
                      path="/team-gründen"
                      component={Team}
                      user={user}
                    />
                    <ProtectedRoute
                      path="/submit-project"
                      component={Projects}
                      user={user}
                    />
                    <ProtectedRoute
                      path="/team-findung/add-team-findung"
                      component={AddTeamFindung}
                    />
                    <ProtectedRoute
                      path="/team-findung"
                      component={TeamFindung}
                    />
                    <ProtectedRoute
                      path="/view-team-findung/:id"
                      component={ViewTeamFindung}
                    />
                    <ProtectedRoute
                      path="/user-profile"
                      component={UserProfile}
                      user={user}
                    />
                    <Route path="/not-found" component={NotFound} />
                    <ProtectedRoute
                      exact
                      path="/"
                      component={Home}
                      user={user}
                    />
                    <Redirect to="/not-found" />
                  </Switch>
                  {location.pathname !== "/auth" &&
                    location.pathname !== "/change-password" &&
                    location.pathname !== "/confirm-user" &&
                    location.pathname !== "/entry-page" &&
                    location.pathname !== "/confirm-team" && <Footer />}
                </React.Fragment>
              </CSSTransition>
            </TransitionGroup>
          </React.Fragment>
        )}
      />
    );
  }
}

export default App;
