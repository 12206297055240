import React from "react";
import Joi from "joi-browser";
import Form from "../common/form";
import auth from "../../services/authService";
import { Redirect } from "react-router-dom";
import logo from "../../img/logo_bwki.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLock, faUser } from "@fortawesome/free-solid-svg-icons";

class Login extends Form {
  state = {
    data: {
      username: "",
      password: "",
    },
    errors: {},
    loginError: "",
    loading: false,
  };

  schema = {
    username: Joi.string().required(),
    password: Joi.string().required(),
  };

  submitForm = () => {
    this.setState({
      loginError: "",
      loading: true,
    });
    const { username, password } = this.state.data;
    auth
      .login(username, password)
      .then(() => {
        const { state } = this.props.location;
        window.location = state ? state.from.pathname : "/";
      })
      .catch((err) => {
        let error = "Es ist ein unerwarteter Fehler aufgetreten!";
        if (err.response && err.response.status === 400) {
          error = err.response.data.error;
        }

        this.setState({
          loginError: error,
          loading: false,
        });
      });
  };

  render() {
    if (auth.getCurrentUser()) return <Redirect to="/" />;
    const { data, errors, loginError, loading } = this.state;
    const { username, password } = data;
    const { activeTab, changeTabs } = this.props;

    return (
      <div
        className={`content-login-wrapper user-content ${
          activeTab === "login" ? "active" : ""
        }`}
      >
        <div className="login-logo">
          <img alt="logo" src={logo} />
          <h1>
            {" "}
            <span>Bundeswettbewerb</span> <br /> Künstliche Intelligenz{" "}
          </h1>
        </div>
        <p className="primary-color-blue-foreground info-warning">
          Achtung: Auch wenn du im vergangenen Jahr schon teilgenommen hast,
          musst du dich neu registrieren.
        </p>
        <form>
          <div className="input-wrapper">
            <FontAwesomeIcon icon={faUser} />
            <input
              className={`${errors.username ? "invalid" : ""}`}
              name="username"
              onChange={this.handleChange}
              value={username}
              type="text"
              placeholder="Benutzername"
            />
          </div>
          <div className="input-wrapper">
            <FontAwesomeIcon className="icon" icon={faLock} />
            <input
              className={`${errors.password ? "invalid" : ""}`}
              name="password"
              onChange={this.handleChange}
              value={password}
              type="password"
              placeholder="Passwort"
            />
          </div>
        </form>
        {loginError && (
          <div className="error text-center">
            <span className="text-danger">{loginError}</span>
          </div>
        )}
        <a href="#/" onClick={() => changeTabs("forgot")}>
          Passwort vergessen?
        </a>
        <div className="button-login">
          <button
            disabled={loading ? true : this.validate()}
            type="button"
            onClick={this.handleSubmit}
          >
            Anmelden
          </button>
        </div>
      </div>
    );
  }
}

export default Login;
