import React from "react";
import Form from "../common/form";
import userService from "../../services/userService";
import teamService from "../../services/teamService";
import TeamGruenden from "./teamGruenden";
// import Joi from "joi-browser";

class Team extends Form {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      leaveStatus: "",
      showPanel: true,
      modal: null,
      errors: [],
    };
  }

  showPanel = (modalItem) => (e) => {
    this.setState({
      showPanel: true,
      modal: {
        title: modalItem.title,
        content: modalItem.content,
      },
    });
  };

  closeDefinition = () => {
    this.setState({
      showPanel: false,
      modal: null,
    });
  };

  componentDidMount() {
    if (this.props.user) {
      console.log("user", this.props.user);
      this.getUser();
    }
  }

  // Fetch user data
  getUser = () => {
    userService
      .getUser()
      .then(({ data: response }) => {
        this.setState({
          data: response,
        });
      })
      .catch((err) => {
        console.log(err);
        // authService.logout();
      });
  };

  leaveMyTeam = (teamName, isTeamCreated) => () => {
    const { data } = this.state;
    this.closeDefinition();
    if (isTeamCreated) {
      teamService
        .leaveTeam(teamName)
        .then(({ data }) => {
          this.getUser();
          this.setState({
            leaveStatus: "true",
            // data: {},
          });
        })
        .catch((err) => {
          console.log(err);
          this.setState({
            leaveStatus: "false",
          });
        });
    }
    setTimeout(() => {
      this.setState({
        leaveStatus: "",
      });
    }, 5000);
  };

  render() {
    const { data, leaveStatus, showPanel, modal } = this.state;

    return (
      <React.Fragment>
        <section className="content question">
          <div className="container-fluid">
            <div className="container-limit">
              <div className={`user-profile`}>
                <TeamGruenden
                  response={data}
                  closeDefinition={this.closeDefinition}
                  viewPanel={this.showPanel}
                  leaveStatus={leaveStatus}
                  showPanel={showPanel}
                  modal={modal}
                  leaveMyTeam={this.leaveMyTeam}
                />
              </div>
            </div>
          </div>
        </section>
      </React.Fragment>
    );
  }
}

export default Team;
