import React from "react";
import Form from "../common/form";
import Joi from "joi-browser";
import projectService from "../../services/projectService";
import { canSubmit } from "../../actions/can-submit";
import { connect } from "react-redux";
// import Completed from './completed';
import SubmitProjectForm from "./submit-project-form";
// import SubmitProjectVideo from './submit-project-video';
import userService from "../../services/userService";
import Loading from "../common/loading";
import ideasService from "../../services/ideasService";
import ProgressBar from "./progress-bar";

class Projects extends Form {
  constructor(props) {
    super(props);

    // Use this flag to check if the component is mounted, before fetching data asynchronously
    this.isComponentMounted = false;
  }

  state = {
    data: {
      teamname: "",
      mitglieder: "",
      titel: "",
      ziel: "",
      ziel_count: 0,
      anwendung: "",
      anwendung_count: 0,
      datensatz: "",
      datensatz_count: 0,
      vorbereitung: "",
      vorbereitung_count: 0,
      dnn: "",
      dnn_count: 0,
      evaluation: "",
      evaluation_count: 0,
      produkt: "",
      produkt_count: 0,
      komponenten: "",
      komponenten_count: 0,
      probleme: "",
      probleme_count: 0,
      quellen: "",
      potential: "",
      potential_count: 0,
      schwachstelle: "",
      schwachstelle_count: 0,
      zukunft: "",
      zukunft_count: 0,
      video: [],
      code: [],
      files: [],
    },
    hasSubmitted: false,
    hasSaved: false,

    errors: {},
    imageError: false,
    showVideo: true,
    loading: false,
    loadingError: "",
    userData: {},
    showSubmission: false,
    notPartOfTeam: false,
    projectSubmitted: false,
    existingFileNames: [],
    togglePopup: false,
    showSaved: false,
    hasSubmittedIdea: false,
    showPDFmodal: false,
    pdfURL: "",
    progressValue: 0,
  };

  loadUser = () => {
    if (this.props.user) {
      userService.getUser().then(({ data: response }) => {
        this.setState({
          userData: response.data,
        });

        // Check if user is part of a team
        if (!response.team) {
          this.setState({
            showSubmission: false,
            notPartOfTeam: true,
          });
          return;
        }

        let updatedData = { ...this.state.data };
        updatedData.teamname = response.team.team_name;

        let defaultMembers = response.team.team_members
          ? response.team.team_members
          : [];
        let membersString = "";
        defaultMembers
          .filter((member) => member.accepted)
          .map((member) => {
            membersString += member.username + ",";
          });
        membersString.substring(0, membersString.length);
        updatedData.mitglieder = membersString;

        const { hasSaved, hasSubmitted, hasSubmittedIdea } = this.state;

        // If user's team has submitted an idea and has saved a project, show submission details
        if (hasSaved && hasSubmittedIdea) {
          //   console.log("there exists a team and saved and submiited idea");

          projectService
            .getProjectByTeam(response.team.team_name)
            .then(({ data: response }) => {
              // console.log("there exists a team - get project");

              const project = response;
              // TODO: update project fields from project to updatedData

              //   console.log(project);

              updatedData.titel = project.titel ? project.titel : "";
              updatedData.ziel = project.ziel ? project.ziel : "";
              updatedData.ziel_count = project.ziel ? project.ziel.length : 0;
              updatedData.anwendung = project.anwendung
                ? project.anwendung
                : "";
              updatedData.anwendung_count = project.anwendung
                ? project.anwendung.length
                : "";
              updatedData.datensatz = project.datensatz
                ? project.datensatz
                : "";
              updatedData.datensatz_count = project.datensatz
                ? project.datensatz.length
                : 0;
              updatedData.vorbereitung = project.vorbereitung
                ? project.vorbereitung
                : "";
              updatedData.vorbereitung_count = project.vorbereitung
                ? project.vorbereitung.length
                : 0;
              updatedData.dnn = project.dnn ? project.dnn : "";
              updatedData.dnn_count = project.dnn ? project.dnn.length : 0;
              updatedData.evaluation = project.eval ? project.eval : "";
              updatedData.evaluation_count = project.eval
                ? project.eval.length
                : 0;
              updatedData.produkt = project.produkt ? project.produkt : "";
              updatedData.produkt_count = project.produkt
                ? project.produkt.length
                : 0;
              updatedData.komponenten = project.komponenten
                ? project.komponenten
                : "";
              updatedData.komponenten_count = project.komponenten
                ? project.komponenten.length
                : 0;
              updatedData.probleme = project.probleme ? project.probleme : "";
              updatedData.probleme_count = project.probleme
                ? project.probleme.length
                : 0;
              updatedData.quellen = project.quellen ? project.quellen : "";
              updatedData.potential = project.potential
                ? project.potential
                : "";
              updatedData.potential_count = project.potential
                ? project.potential.length
                : 0;
              updatedData.schwachstelle = project.schwachstelle
                ? project.schwachstelle
                : "";
              updatedData.schwachstelle_count = project.schwachstelle
                ? project.schwachstelle.length
                : 0;
              updatedData.zukunft = project.zukunft ? project.zukunft : "";
              updatedData.zukunft_count = project.zukunft
                ? project.zukunft.length
                : 0;

              //   console.log(updatedData);
              let files;
              if (hasSubmitted) {
                files =
                  project.file && project.file.length > 0
                    ? project.file.map((file) => {
                        return file.split("\\").pop().split("/").pop();
                      })
                    : [];
                // If project has already been submitted, fetch PDF link
                projectService
                  .getProjectReport(updatedData.teamname)
                  .then(({ data }) => {
                    console.log("Loaded user, fetching pdf url");
                    this.setState({
                      pdfURL: data.url,
                    });
                  });
              }
              this.setState({
                data: updatedData,
                existingFileNames: files,
                showSubmission: true,
              });
            })
            .catch((error) => {
              console.log(error);
              // let updatedData = {...this.state.userData}
              this.initDefaultStates(updatedData);
              // this.setState({showSubmission:true})
            });
        } else {
          // no project saved
          this.initDefaultStates(updatedData);
          this.setState({ showSubmission: hasSubmittedIdea ? true : false });
        }
      });
    }
  };

  initDefaultStates(updatedData) {
    ideasService
      .getIdeaTitle()
      .then(({ data }) => {
        updatedData.titel = data.titel;
        this.setState({
          data: updatedData,
          projectSubmitted: false,
          existingFileNames: [],
        });
        console.log("idea title: " + data.titel);
      })
      .catch((error) => {
        console.log("error fetching idea title " + JSON.stringify(error));
        this.setState({
          data: updatedData,
          projectSubmitted: false,
          existingFileNames: [],
        });
      });
  }

  componentDidMount() {
    this.isComponentMounted = true;
    this.props.canSubmitProject();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.canSubmitProp) {
      // console.log("can submit - passed");
      // console.log(nextProps.canSubmitProp);

      // Check if user has submitted an idea
      ideasService.hasSubmited().then(({ data }) => {
        if (this.isComponentMounted) {
          this.setState({
            hasSubmittedIdea: data.hasSubmit,
            showSubmission: data.hasSubmit ? true : false,
          });
        }
      });

      // Check if user has submitted a project
      projectService.hasSubmited().then(({ data }) => {
        // console.log("on has submitted");
        if (this.isComponentMounted) {
          this.setState({
            hasSubmitted: data.hasSubmit,
            notPartOfTeam: !data.team,
            hasSaved: data.hasSaved,
          });
          // console.log("get user call");
          //   if (data.hasSubmit) {
          //   }
          return this.loadUser();
        }
      });
    }
  }

  componentWillUnmount() {
    this.isComponentMounted = false;
  }

  showImageError = (show) => {
    this.setState({
      imageError: show,
    });
  };

  // Perform Word Count
  handleTextChange = (name, max_count) => (event) => {
    const { data } = this.state;
    let words = event.target.value.split(" ");
    if (words.length >= max_count) {
      let vals = "";
      words.forEach(function (word, idx) {
        if (idx === max_count - 1) {
          vals += word.trim();
        } else if (idx < max_count) {
          vals += word + " ";
        }
      });
      if (name === "evaluation") {
        data.evaluation = vals;
        data.evaluation_count = max_count;
      }
    } else {
      if (name === "evaluation") {
        data.evaluation = event.target.value;
        if (words.length > 0) {
          data.evaluation_count = words.length;
        }
      }
    }

    this.setState({
      data: data,
    });
  };

  //Perform char counts
  handleCharChange = (name, max_count) => (event) => {
    const { data } = this.state;
    let title = event.target.value;
    switch (name) {
      case "ziel":
        if (title.length >= max_count) {
          data.ziel = title.substring(0, max_count);
          data.ziel_count = data.ziel.length;
        } else {
          data.ziel = event.target.value;
          data.ziel_count = data.ziel.length;
        }
        break;
      case "anwendung":
        if (title.length >= max_count) {
          data.anwendung = title.substring(0, max_count);
          data.anwendung_count = data.anwendung.length;
        } else {
          data.anwendung = event.target.value;
          data.anwendung_count = data.anwendung.length;
        }
        break;
      case "datensatz":
        if (title.length >= max_count) {
          data.datensatz = title.substring(0, max_count);
          data.datensatz_count = data.datensatz.length;
        } else {
          data.datensatz = event.target.value;
          data.datensatz_count = data.datensatz.length;
        }
        break;
      case "vorbereitung":
        if (title.length >= max_count) {
          data.vorbereitung = title.substring(0, max_count);
          data.vorbereitung_count = data.vorbereitung.length;
        } else {
          data.vorbereitung = event.target.value;
          data.vorbereitung_count = data.vorbereitung.length;
        }
        break;
      case "dnn":
        if (title.length >= max_count) {
          data.dnn = title.substring(0, max_count);
          data.dnn_count = data.dnn.length;
        } else {
          data.dnn = event.target.value;
          data.dnn_count = data.dnn.length;
        }
        break;
      case "evaluation":
        if (title.length >= max_count) {
          data.evaluation = title.substring(0, max_count);
          data.evaluation_count = data.evaluation.length;
        } else {
          data.evaluation = event.target.value;
          data.evaluation_count = data.evaluation.length;
        }
        break;
      case "produkt":
        if (title.length >= max_count) {
          data.produkt = title.substring(0, max_count);
          data.produkt_count = data.produkt.length;
        } else {
          data.produkt = event.target.value;
          data.produkt_count = data.produkt.length;
        }
        break;
      case "komponenten":
        if (title.length >= max_count) {
          data.komponenten = title.substring(0, max_count);
          data.komponenten_count = data.komponenten.length;
        } else {
          data.komponenten = event.target.value;
          data.komponenten_count = data.komponenten.length;
        }
        break;
      case "probleme":
        if (title.length >= max_count) {
          data.probleme = title.substring(0, max_count);
          data.probleme_count = data.probleme.length;
        } else {
          data.probleme = event.target.value;
          data.probleme_count = data.probleme.length;
        }
        break;
      case "quellen":
        data.quellen = event.target.value;
        break;
      case "potential":
        if (title.length >= max_count) {
          data.potential = title.substring(0, max_count);
          data.potential_count = data.potential.length;
        } else {
          data.potential = event.target.value;
          data.potential_count = data.potential.length;
        }
        break;
      case "schwachstelle":
        if (title.length >= max_count) {
          data.schwachstelle = title.substring(0, max_count);
          data.schwachstelle_count = data.schwachstelle.length;
        } else {
          data.schwachstelle = event.target.value;
          data.schwachstelle_count = data.schwachstelle.length;
        }
        break;
      case "zukunft":
        if (title.length >= max_count) {
          data.zukunft = title.substring(0, max_count);
          data.zukunft_count = data.zukunft.length;
        } else {
          data.zukunft = event.target.value;
          data.zukunft_count = data.zukunft.length;
        }
        break;
    }

    this.setState({
      data: data,
    });
  };

  schema = {
    teamname: Joi.string().required(),
    mitglieder: Joi.string().required(),
    titel: Joi.string().required(),
    ziel: Joi.string().required(),
    ziel_count: Joi.number().optional(),
    anwendung: Joi.string().required(),
    anwendung_count: Joi.number().optional(),
    datensatz: Joi.string().required(),
    datensatz_count: Joi.number().optional(),
    vorbereitung: Joi.string().required(),
    vorbereitung_count: Joi.number().optional(),
    dnn: Joi.string().required(),
    dnn_count: Joi.number().optional(),
    evaluation: Joi.string().required(),
    evaluation_count: Joi.number().optional(),
    produkt: Joi.string().required(),
    produkt_count: Joi.number().optional(),
    komponenten: Joi.string().required(),
    komponenten_count: Joi.number().optional(),
    probleme: Joi.string().required(),
    probleme_count: Joi.number().optional(),
    quellen: Joi.string().optional(),
    potential: Joi.string().required(),
    potential_count: Joi.number().optional(),
    schwachstelle: Joi.string().required(),
    schwachstelle_count: Joi.number().optional(),
    zukunft: Joi.string().required(),
    zukunft_count: Joi.number().optional(),
    video: Joi.array(),
    code: Joi.array(),
    files: Joi.array(),
  };

  closePDFmodal = () => {
    this.setState({
      showPDFmodal: false,
    });
  };

  // Function to track upload progress
  updateProgress = (percentComplete) => {
    // console.log("progress: ", percentComplete, "%");
    this.setState({
      progressValue: percentComplete,
    });
  };

  handleSave = (e) => {
    e.preventDefault();
    // console.log("in save");
    const { canSubmitProp, canSubmitProject } = this.props;
    canSubmitProject();
    if (!canSubmitProp) return false;

    this.setState({
      loading: true,
      loadingError: "",
    });

    const {
      teamname,
      mitglieder,
      titel,
      ziel,
      anwendung,
      datensatz,
      vorbereitung,
      dnn,
      evaluation,
      produkt,
      komponenten,
      probleme,
      quellen,
      potential,
      schwachstelle,
      zukunft,
      video,
      code,
      files,
    } = this.state.data;

    let submitted = false;

    // Project update
    if (this.state.hasSaved) {
      // console.log("update project");
      projectService
        .updateProject(
          teamname,
          mitglieder,
          titel,
          ziel,
          anwendung,
          datensatz,
          vorbereitung,
          dnn,
          evaluation,
          produkt,
          komponenten,
          probleme,
          quellen,
          potential,
          schwachstelle,
          zukunft,
          video,
          code,
          files,
          submitted,
          this.updateProgress
        )
        .then(({ data }) => {
          this.setState({
            showSaved: true,
            loading: false,
            loadingError: "",
            togglePopup: false,
            progressValue: 0,
          });
        })
        .catch((err) => {
          console.log(err);
          let error =
            "Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal! ";
          if (err.response && err.response.status === 400) {
            error = err.response.data.error;
          }
          // Large file size error from NGINX
          else if (err.response && err.response.status === 413) {
            error = "err.response.data.error";
          } else {
            // Catch all other errors
            error = error.concat(err);
          }
          this.setState({
            loading: false,
            loadingError: error,
            togglePopup: false,
            progressValue: 0,
          });
        });
    } else {
      // console.log("add project");

      // Add new project
      projectService
        .addProject(
          teamname,
          mitglieder,
          titel,
          ziel,
          anwendung,
          datensatz,
          vorbereitung,
          dnn,
          evaluation,
          produkt,
          komponenten,
          probleme,
          quellen,
          potential,
          schwachstelle,
          zukunft,
          video,
          code,
          files,
          submitted,
          this.updateProgress
        )
        .then(({ data }) => {
          this.setState({
            showSaved: true,
            loading: false,
            loadingError: "",
            togglePopup: false,
            hasSaved: true,
            progressValue: 0,
          });
        })
        .catch((err) => {
          console.log(err);
          let error =
            "Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.";
          // Mongo Error
          if (err.response && err.response.status === 400) {
            error = err.response.data.error;
          }
          // Partially uploaded file error
          else if (err.response && err.response.status === 422) {
            error = err.response.data.error;
          }
          // All other errors
          else {
            error = error + "\n " + err;
          }
          this.setState({
            loading: false,
            loadingError: error,
            togglePopup: false,
            progressValue: 0,
          });
        });
    }
  };

  submitForm = () => {
    const { canSubmitProp, canSubmitProject } = this.props;
    canSubmitProject();
    if (!canSubmitProp) return false;

    // project submitted
    let submitted = true;

    const {
      teamname,
      mitglieder,
      titel,
      ziel,
      anwendung,
      datensatz,
      vorbereitung,
      dnn,
      evaluation,
      produkt,
      komponenten,
      probleme,
      quellen,
      potential,
      schwachstelle,
      zukunft,
      video,
      code,
      files,
    } = this.state.data;

    this.setState({
      loading: true,
      loadingError: "",
    });

    if (this.state.hasSaved) {
      projectService
        .updateProject(
          teamname,
          mitglieder,
          titel,
          ziel,
          anwendung,
          datensatz,
          vorbereitung,
          dnn,
          evaluation,
          produkt,
          komponenten,
          probleme,
          quellen,
          potential,
          schwachstelle,
          zukunft,
          video,
          code,
          files,
          submitted,
          this.updateProgress
        )
        .then(({ data }) => {
          this.setState({
            hasSubmitted: true,
            showCompleted: true,
            loading: false,
            loadingError: "",
            togglePopup: false,
          });
          this.generatePDF();
          window.scrollTo(0, 0);
          //   window.scrollTo({top:0, left:0, behavior: "smooth",});
        })
        .catch((err) => {
          console.log("Caught error submitting project as PUT");
          console.log(err);
          let error =
            "Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.";
          if (err.response && err.response.status === 400) {
            error = err.response.data.error;
          } else if (err.response && err.response.status === 413) {
            error = "err.response.data.error";
          } else {
            // error = error.concat(err);
            error = error + "\n " + err;
          }
          this.setState({
            loading: false,
            loadingError: error,
            togglePopup: false,
            progressValue: 0,
          });
          return;
        });
    } else {
      projectService
        .addProject(
          teamname,
          mitglieder,
          titel,
          ziel,
          anwendung,
          datensatz,
          vorbereitung,
          dnn,
          evaluation,
          produkt,
          komponenten,
          probleme,
          quellen,
          potential,
          schwachstelle,
          zukunft,
          video,
          code,
          files,
          submitted,
          this.updateProgress
        )
        .then(({ data }) => {
          this.setState({
            hasSubmitted: true,
            showCompleted: true,
            loading: false,
            loadingError: "",
            togglePopup: false,
          });
          this.generatePDF();
        })
        .catch((err) => {
          console.log(err);
          let error =
            "Ups, es ist etwas schief gelaufen. Bitte versuche es nochmal!.";
          if (err.response && err.response.status === 400) {
            error = err.response.data.error;
          } else if (err.response && err.response.status === 413) {
            error = "err.response.data.error";
          } else if (err.response) {
            error = error + " <br/> " + err.response;
          }

          this.setState({
            loading: false,
            loadingError: error,
            togglePopup: false,
            progressValue: 0,
          });
          return;
        });
    }
  };

  // Generate PDF if project has been submitted
  generatePDF = () => {
    const { teamname } = this.state.data;

    if (this.state.hasSubmitted) {
      console.log("Fetching pdf.");
      projectService
        .getProjectReport(teamname)
        .then(({ data }) => {
          this.setState({
            loading: false,
            pdfURL: data.url,
          });
        })
        .catch((err) => {
          this.setState({
            loading: false,
            loadingError: "Ran into an error while fetching the PDF!",
          });
          console.log(err);
        });
    }
  };

  hideVideo = () => {
    this.setState({
      showVideo: false,
    });
  };

  showConfirmationPopup = (event) => {
    event.preventDefault();
    this.setState({
      togglePopup: true,
    });
  };

  onClosePupup = () => {
    this.setState({
      togglePopup: false,
    });
  };
  onSubmitConfirmed = (event) => {
    this.handleSubmit(event);
  };

  onValidate = () => {
    let errors = this.validate();

    if (!this.state.data.code || this.state.data.code.length === 0) {
      errors = errors ? errors : {};
      errors["code"] = "Codedatei darf nicht leer sein!";
    }

    if (!this.state.data.video || this.state.data.video.length === 0) {
      errors = errors ? errors : {};
      errors["video"] = "Videodatei darf nicht leer sein!";
    }
    return errors;
  };

  resetFormData(updatedData) {
    // updatedData.titel = "";
    updatedData.ziel = "";
    updatedData.anwendung = "";
    updatedData.datensatz = "";
    updatedData.vorbereitung = "";
    updatedData.dnn = "";
    updatedData.evaluation = "";
    updatedData.produkt = "";
    updatedData.komponenten = "";
    updatedData.probleme = "";
    updatedData.quellen = "";
    updatedData.potential = "";
    updatedData.schwachstelle = "";
    updatedData.zukunft = "";
    return updatedData;
  }

  render() {
    const {
      hasSubmitted,
      showCompleted,
      showSaved,
      togglePopup,
      data,
      loading,
      loadingError,
      //   userData,
      showSubmission,
      notPartOfTeam,
      hasSubmittedIdea,
      existingFileNames,
      imageError,
      progressValue,
      showPDFmodal,
      pdfURL,
    } = this.state;
    const { canSubmitProp } = this.props;

    return (
      <React.Fragment>
        <div className={`user-popup ${togglePopup ? "active" : ""}`}>
          <div className={`popup-box`}>
            <span className={`text`}>
              Wenn du das Projekt einreichst, kannst du es anschliessend nicht
              mehr ändern.
            </span>
            <div className="popup-bar">
              <ProgressBar progressPercent={progressValue}></ProgressBar>
            </div>
            <div className={`popup-buttons`}>
              <button
                disabled={this.state.loading}
                onClick={this.onSubmitConfirmed}
              >
                Bestätigen
              </button>
              <button
                disabled={this.state.loading}
                onClick={this.onClosePupup.bind(this, "")}
              >
                Ablehnen
              </button>
            </div>
            {this.state.loading && (
              <div className="in-progress">bitte warten.....</div>
            )}
          </div>
        </div>

        {
          // !showCompleted && !showSaved &&
          <section className="content question">
            <div className="container-fluid">
              <div className="container-limit my-limit">
                <React.Fragment>
                  {showSubmission && (
                    <SubmitProjectForm
                      canSubmitProp={canSubmitProp}
                      loading={loading}
                      loadingError={loadingError}
                      data={data}
                      handleChange={this.handleChange}
                      //   handleTextChange={this.handleTextChange}
                      handleCharChange={this.handleCharChange}
                      //   handleSubmit={this.hanedleProject}
                      validate={this.onValidate}
                      showImageError={this.showImageError}
                      //   user={userData}
                      showConfirmationPopup={this.showConfirmationPopup}
                      handleSave={this.handleSave}
                      projectSubmitted={hasSubmitted}
                      existingFileNames={existingFileNames}
                      showSaved={showSaved}
                      showCompleted={showCompleted}
                      imageError={imageError}
                      pdfURL={pdfURL}
                    />
                  )}
                  {notPartOfTeam && (
                    <div className="submitedIdea">
                      <div className="submited-wrapper">
                        <h1>
                          Um ein Projektidee oder ein Projekt einzureichen,
                          musst Du als Mitglied eines Teams registriert sein.
                        </h1>
                      </div>
                    </div>
                  )}
                  {this.props.canSubmitProp &&
                    !hasSubmittedIdea &&
                    !notPartOfTeam && (
                      <div className="submitedIdea">
                        <div className="submited-wrapper">
                          <h1>
                            Um ein Projekt einzureichen, müssen Sie zunächst
                            eine Idee einreichen.
                          </h1>
                        </div>
                      </div>
                    )}
                  {!this.props.canSubmitProp && (
                    <div className="submitedIdea">
                      <div className="submited-wrapper">
                        <h1>
                          Die Frist für die Einreichung von Projekten ist
                          abgelaufen. Sie können jetzt kein Projekt einreichen.
                        </h1>
                      </div>
                    </div>
                  )}
                  {this.props.canSubmitProp &&
                    !notPartOfTeam &&
                    !hasSubmitted &&
                    hasSubmittedIdea &&
                    !showSubmission && <Loading />}
                  {/* {showPDFmodal && (
                    <div className="overlay-panel-container">
                      <div className="overlay-panel">
                        <div
                          className="close"
                          onClick={this.closePDFmodal}
                        ></div>
                        <div>
                          <a
                            href={pdfURL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {" "}
                            Open submission PDF
                          </a>
                        </div>
                      </div>
                    </div>
                  )} */}
                </React.Fragment>
              </div>
            </div>
          </section>
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    canSubmitProp: state.canSubmit,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    canSubmitProject: () => {
      dispatch(canSubmit("project"));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Projects);
