import http from "./httpService";

const { REACT_APP_WEB_API_URL: web_api_url } = process.env;

const multipartHeader = {
  headers: {
    "content-type": "multipart/form-data",
  },
};

export async function submitIdea(
  teamName,
  thema,
  description,
  origin,
  files,
  submitted
) {
  let data = new FormData();
  data.append("teamName", teamName);
  data.append("thema", thema);
  data.append("description", description);
  data.append("origin", origin);
  data.append("submitted", submitted);

  if (files) {
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i], files[i].name);
    }
  }

  return await http.post(`${web_api_url}/idea`, data, multipartHeader);
}

export async function hasSubmited() {
  return await http.get(`${web_api_url}/has-submit-idea`);
}

export async function getIdeaByTeam(teamName) {
  return await http.get(`${web_api_url}/idea/${teamName}`);
}

export async function getIdeaTitle() {
  return await http.get(`${web_api_url}/get-idea-title`);
}

export async function updateIdea(
  teamName,
  thema,
  description,
  origin,
  files,
  fileDeleted,
  submitted
) {
  let data = new FormData();
  data.append("teamName", teamName);
  data.append("thema", thema);
  data.append("description", description);
  data.append("origin", origin);
  data.append("fileDeleted", fileDeleted);
  data.append("submitted", submitted);

  if (files) {
    for (let i = 0; i < files.length; i++) {
      data.append("files", files[i], files[i].name);
    }
  }

  return await http.put(
    `${web_api_url}/idea/${teamName}`,
    data,
    multipartHeader
  );
}

export default {
  submitIdea,
  hasSubmited,
  getIdeaByTeam,
  getIdeaTitle,
  updateIdea,
};
