import React, { Component } from "react";
import authService from "../services/authService";
import { Link } from "react-router-dom";
import * as alert from "../alert.json";
import userService from "../services/userService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";

class Home extends Component {
  state = {
    paths: [
      {
        title: "Team",
        description:
          "Um beim BWKI mitzumachen musst du zuerst ein Team aus 1-4 TeilnehmerInnen gründen.",
        link: "/team-gründen",
        image: "team.svg",
        alt: "Team gründen icon",
        styleClass: "primary-color-blue-background",
      },
      {
        title: "Idea",
        description:
          "Du bist schon in einem Team? Dann könnt ihr jetzt eure Idee anmelden.",
        link: "/submit-ideas",
        image: "idea.svg",
        alt: "idea icon",
        styleClass: "primary-color-lightblue-background",
      },
      {
        title: "Project",
        description:
          "Hier könnt ihr das Projekt zu eurer angemeldeten Idee einreichen.",
        link: "/submit-project",
        image: "project.svg",
        alt: "project icon",
        styleClass: "primary-color-lightgreen-background",
      },
    ],
    showAlert: false,
    showPanel: false,
    showMissingYearPanel: false,
  };

  componentDidMount() {
    if (alert.home.enabled) {
      this.setState({
        showAlert: true,
      });
    }

    // 2024 Update: Fetch user info and check if user has yearSchoolExam
    if (this.props.user) {
      userService.getUser().then(({ data: response }) => {
        if (!response.year_school_exam) {
          this.setState({
            showMissingYearPanel: true,
          });
        }
      });
    }
  }

  toggleModal = () => {
    this.setState({
      showPanel: !this.state.showPanel,
    });
  };

  removeAlert = () => {
    this.setState({
      showAlert: false,
    });
  };
  render() {
    const user = authService.getCurrentUser();
    const { showPanel, showMissingYearPanel } = this.state;

    if (!user) this.props.history.push("/auth");
    return (
      <React.Fragment>
        <section className="content homepage">
          <div>
            <div
              className="homepage header2 font-sub-header text-center"
              style={{ marginBottom: "0.25rem" }}
            >
              Ihr wollt beim Bundeswettbewerb KI mitmachen und eure eigene Idee
              verwirklichen?
            </div>
            <h1
              className="homepage header1 font-main-header text-center message-default"
              onClick={this.toggleModal}
            >
              So geht’s!
            </h1>
          </div>
          <div className="container-fluid home-wrapper">
            <div className="path-tiles">
              {this.state.paths.map((path) => (
                <Link
                  to={`${path.link}`}
                  key={path.link}
                  className="homepage-tile layout"
                >
                  <div className="layout-wrapper">
                    <img
                      className="tile-image"
                      src={`/images/icons/paths/${path.image}`}
                      alt={path.alt}
                    />
                    <div className="tile-text"> {path.description}</div>
                  </div>
                </Link>
              ))}
            </div>
            <div className="homepage header1 font-main-header text-center">
              Tauscht euch im{" "}
              <a
                className="link-default"
                href="https://discord.gg/J6T5pJyVa8"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  className="social-logo"
                  src={`/images/icons/media/discord.svg`}
                  alt="discord icon"
                />
                Chat
              </a>{" "}
              aus.
            </div>
          </div>

          {showMissingYearPanel && (
            <div className="overlay-panel-container">
              <div className="overlay-panel competition-home warning">
                <div>
                  <div className="section">
                    <h2 className="text-center">Hinweis</h2>
                    <p className="text-warning">
                      <br />
                      <strong>
                        Um am Wettbewerb teilnehmen zu können, musst du
                        angeben,wann dein Schulabschluss war oder sein wird.
                        Bitte gib uns noch diese Information in deinem
                        Benutzerprofil aus.
                      </strong>
                      <br />
                    </p>
                    <br />
                    <Link to="/user-profile">
                      <h2 className="text-center to-profile-link">
                        Gehen Sie zu Ihrem Benutzerprofil{" "}
                        <FontAwesomeIcon icon={faChevronRight} />
                      </h2>
                    </Link>
                    <br />
                  </div>
                </div>
                {/* <div className="close" onClick={this.toggleModal}></div> */}
              </div>
            </div>
          )}

          {showPanel && (
            <div className="overlay-panel-container">
              <div className="overlay-panel competition-home">
                <div>
                  <div className="section">
                    <p>
                      Um ein Projekt beim Bundeswettbewerb KI einzureichen, müßt
                      ihr:
                      <br />
                      <span className="points">
                        (1) ein Team gründen (auch wenn ihr nur eine Person
                        seid),
                      </span>
                      <br />
                      <span className="points">
                        (2) eure Projektidee anmelden und
                      </span>
                      <br />
                      <span className="points">
                        (3) euer Projekt anschließend einreichen.
                      </span>
                      <br />
                      <br />
                      Für die <strong>Projekteinreichung</strong> benötigen wir
                      einige Informationen zu eurer Arbeit. Die entsprechenden
                      Textfelder könnt ihr bereits{" "}
                      <strong>vor der Einreichung ausfüllen</strong>,
                      abspeichern und bis zur Einreichung verändern.
                      <br />
                      <br />
                      Ihr sucht noch nach Teampartnern? Dann schaut in der
                      Rubrik <strong>Team finden</strong> oder annonciert
                      selbst.
                      <br />
                      <br />
                      {/* Zusätzlich könnt ihr euer Projekt grafisch darstellen und den <strong>Postkartenwettbewerb</strong> gewinnen. Mehr Infos findet ihr in der entsprechenden Rubrik. */}
                    </p>
                  </div>
                </div>
                <div className="close" onClick={this.toggleModal}></div>
              </div>
            </div>
          )}
        </section>
      </React.Fragment>
    );
  }
}

export default Home;
